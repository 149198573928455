import { Link, NavLink, useMatch } from 'react-router-dom';
import { useState } from 'react';
import './MainNavigation.css';
import { useTranslation } from 'react-i18next';
import arrowDown from '../assets/img/arrow-down.svg';
import arrowBlue from '../assets/img/arrow-blue-right.svg';
import checkbox from '../assets/img/checkbox.svg';
import checkboxSelected from '../assets/img/checkbox-selected.svg';
import langIcon from '../assets/img/lang-icon.svg';
import sqarLogo from '../assets/img/sqare-logo-header.svg';

function LearnMoreBlock({ title, description, src, alt, link }) {
    const { t } = useTranslation();

    return (
        // <Link to={link}>
        <a href={link} className="learn-more-block">
            {/* <div className="learn-more-block"> */}
            <div className="title-description-wrapper">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </div>
            <div className="learn-more-wrapper">
                <div className="learn-more">
                    <Link to={link}>{t('nav.products.learn')}</Link>
                    {/* <div>{t('nav.products.learn')}</div> */}
                </div>
                <img loading="lazy" src={src} alt={alt} className="arrow-icon" />
            </div>
            {/* </div> */}
        </a>
        // </Link>
    );
}

function MainNavigation() {
    const loginUrl = process.env.REACT_APP_LOGIN_URL;
    const { t, i18n } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const [showLangSwitcher, setShowLangSwitcher] = useState(false);

    const selectLanguage = () => {
        setShowLangSwitcher((prevShowLangSwitcher) => !prevShowLangSwitcher);
    };

    const useRouteMatch = (path, exact = true) => {
        const result = useMatch(exact ? path : `${path}/*`);
        return result ? 'active' : '';
    };

    function MenuItem({ children, path, exact }) {
        return <div className={`menu-item ${useRouteMatch(path, exact)}`}>{children}</div>;
    }

    function MenuLoginItem({ children, path, exact }) {
        return <div className={`menu-item ${useRouteMatch(path, exact)}`}>{children}</div>;
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowLangSwitcher(false); // Zwiń listę po zmianie języka
    };

    return (
        <>
            <div className="main-wrapper">
                <div className="header-wrapper">
                    <div className="header-inner-wrapper">
                        <div className="header-content-wrapper">
                            <div className="logo-wrapper">
                                <img loading="lazy" src={sqarLogo} alt="Company Logo" className="logo" />
                            </div>
                            <div className="menu-wrapper">
                                <MenuItem path="/">
                                    <NavLink to="/" className="nav-link">
                                        {t('nav.home')}
                                    </NavLink>
                                </MenuItem>
                                <div className="products-menu-wrapper" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                    <MenuItem path="/products" exact={false}>
                                        <div className="dropdown">
                                            <div className="menu-item-title">{t('nav.products')}</div>
                                            <img
                                                loading="lazy"
                                                src={arrowDown}
                                                alt="Expand dropdown"
                                                className={isHovered ? 'dropdown-icon-up' : 'dropdown-icon'}
                                            />
                                        </div>
                                    </MenuItem>
                                </div>
                                <MenuItem path="/team">
                                    <NavLink to="/team" className="nav-link">
                                        {t('nav.team')}
                                    </NavLink>
                                </MenuItem>
                                <MenuItem path="/careers">
                                    <NavLink to="/careers" className="nav-link">
                                        {t('nav.careers')}
                                    </NavLink>
                                </MenuItem>
                                <MenuItem path="/contact">
                                    <NavLink to="/contact" className="nav-link">
                                        {t('nav.contact')}
                                    </NavLink>
                                </MenuItem>
                            </div>
                        </div>
                        <div className="login-wrapper">
                            <MenuLoginItem path="/login">
                                <NavLink to={loginUrl} className="nav-link lang-nav-link">
                                    {t('nav.login')}
                                </NavLink>
                            </MenuLoginItem>
                        </div>
                        <div className="language-container">
                            <div
                                className={showLangSwitcher ? 'language-switcher-container active' : 'language-switcher-container'}
                                onClick={selectLanguage}
                            >
                                <img src={langIcon} alt="Language icon" className="lang-icon" />
                                <div className="language-switcher-wrapper">
                                    {i18n.language === 'en-EN' ? (
                                        <div className="language-switcher-inner-wrapper">
                                            <div className="lang-item">ENG</div>
                                            <img
                                                loading="lazy"
                                                src={arrowDown}
                                                alt="Expand dropdown"
                                                className={showLangSwitcher ? 'dropdown-icon-up' : 'dropdown-icon'}
                                            />
                                        </div>
                                    ) : (
                                        <div className="language-switcher-inner-wrapper">
                                            <div className="lang-item">POL</div>
                                            <img
                                                loading="lazy"
                                                src={arrowDown}
                                                alt="Expand dropdown"
                                                className={showLangSwitcher ? 'dropdown-icon-up' : 'dropdown-icon'}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {showLangSwitcher ? (
                                <div className="lang-container">
                                    <div className="div-wrapper">
                                        <div className="text-wrapper">{t('nav.chooseLang')}</div>
                                    </div>
                                    <div className="lang-items-wrapper" onClick={() => changeLanguage('en-EN')}>
                                        <div className="lang-item">English</div>
                                        {i18n.language === 'en-EN' ? (
                                            <img src={checkboxSelected} alt="checkbox-selected" />
                                        ) : (
                                            <img src={checkbox} alt="checkbox" />
                                        )}
                                    </div>
                                    <div className="lang-items-wrapper" onClick={() => changeLanguage('pl-PL')}>
                                        <div className="lang-item">Polski</div>
                                        {i18n.language === 'pl-PL' ? (
                                            <img src={checkboxSelected} alt="checkbox-selected" />
                                        ) : (
                                            <img src={checkbox} alt="checkbox" />
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    className={isHovered ? 'content-section content-highlight' : 'content-section'}
                >
                    <div className="blocks-wrapper">
                        <div className="block">
                            <LearnMoreBlock
                                title={t('nav.products.0.title')}
                                description={t('nav.products.0.description')}
                                src={arrowBlue}
                                alt={t('nav.products.0.alt')}
                                link="/products/genspace"
                            />
                            <LearnMoreBlock
                                title={t('nav.products.1.title')}
                                description={t('nav.products.1.description')}
                                src={arrowBlue}
                                alt={t('nav.products.1.alt')}
                                link="/products/budget-estimate"
                            />
                            <LearnMoreBlock
                                title={t('nav.products.2.title')}
                                description={t('nav.products.2.description')}
                                src={arrowBlue}
                                alt={t('nav.products.2.alt')}
                                link="/products/leasing"
                            />
                            <LearnMoreBlock
                                title={t('nav.products.3.title')}
                                description={t('nav.products.3.description')}
                                src={arrowBlue}
                                alt={t('nav.products.3.alt')}
                                link="/products/management"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainNavigation;
